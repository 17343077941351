import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"


const AboutPage = () => {
    return (
        <Layout>
            <SEO title="About" />
            <h1>About</h1>
        </Layout>
    )
}

export default AboutPage
